import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CommentType, Influencer, InfluencerListInfluencerState, Networks} from "../../types/dbModels";
import {ROUTES} from "../../constants/routes";
import {DataTable} from "grommet";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useCustomer} from "../../contexts/CustomerContext";
import {useNavigate, useParams} from "react-router-dom";
import {formatNumber, formatPercentage, generateUid} from "../../helpers/functions";
import imgVerified from "../../assets/images/verified.png";
import InfluencerCommentsModal from "../modal/InfluencerCommentsModal";
import StateJudging from "./StateJudging";
import Loading from "../loading/Loading";
import {useTranslation} from "react-i18next";

import chartLogo from "../../assets/images/charte-ethique.png";

export default function CampaignJudging() {
    // Hooks
    const navigate = useNavigate();
    const params = useParams();
    const {slug} = useParams();
    const {
        refreshInfos,
        influencersByCategory,
        influencerList,
        influencers,
        customerLoading,
    } = useCustomer();

    // States
    const [typeOfList, setTypeOfList] = useState<"cards" | "list" | "state" | "network">('list');
    const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>("");

    const commentModalName = "comment_modal_" + generateUid();


    // Handlers
    const handleSelectInfluencerComment = (influencerId: string) => {
        if (influencerId === selectedInfluencerId) {
            (window as any)[commentModalName].showModal();
            return;
        }
        setSelectedInfluencerId(influencerId)
    }

    useEffect(() => {
        if (selectedInfluencerId) {
            (window as any)[commentModalName].showModal();
        }
    }, [selectedInfluencerId]);

    // Methods

    if (customerLoading.influencer) return <Loading/>

    return (
        <div>
            {/* Type of lists selector */}
            {influencers.length > 0 && (
                <div className="btn-group">
                    <button
                        type='button'
                        className={`btn ${typeOfList === 'cards' ? 'btn-active' : ''}`}
                        onClick={() => {
                            localStorage.setItem('clarkmachine-client-sort-influencers-view', 'cards');
                            setTypeOfList('cards');
                        }}
                    >
                        <FontAwesomeIcon size="xl" icon={['fas', 'id-card-clip']}/>
                    </button>
                    <button
                        type='button'
                        className={`btn ${typeOfList === 'list' ? 'btn-active' : ''}`}
                        onClick={() => {
                            localStorage.setItem('clarkmachine-client-sort-influencers-view', 'list');
                            setTypeOfList('list');
                        }}
                    >
                        <FontAwesomeIcon size="lg" icon={['fas', 'list']}/>
                    </button>
                    <button
                        type='button'
                        className={`btn ${typeOfList === 'state' ? 'btn-active' : ''}`}
                        onClick={() => {
                            localStorage.setItem('clarkmachine-client-sort-influencers-view', 'state');
                            setTypeOfList('state');
                        }}
                    >
                        <FontAwesomeIcon size="lg" icon={['fas', 'heart']}/>
                    </button>
                    <button
                        type='button'
                        className={`btn ${typeOfList === 'network' ? 'btn-active' : ''}`}
                        onClick={() => {
                            localStorage.setItem('clarkmachine-client-sort-influencers-view', 'network');
                            setTypeOfList('network');
                        }}
                    >
                        <FontAwesomeIcon size="lg" icon={['fas', 'icons']}/>
                    </button>
                </div>
            )}


            {/* CARDS OF INFLUENCERS */}
            {typeOfList === "cards" && (
                <div>
                    {influencerList?.influencersCategories.map((category) => (
                        <div className='card p-4 my-4 card-border-dotted' key={category.id}>
                            <h2 className='text-2xl font-bold'>{category.name}</h2>
                            <div className="card-body">
                                <div className="flex flex-wrap items-center gap-8"
                                     style={{minHeight: '10rem'}}>
                                    {
                                        influencersByCategory[category.id] &&
                                        (influencersByCategory[category.id] as Influencer[]).map((influencer, index) => {
                                            let newRouteProfile = ROUTES.CUSTOMER.INFLUENCER.replace(":slug", params.slug as string).replace(":influencerId", influencer.userId);

                                            // @ts-ignore
                                            const entry = influencerList.influencersCategories
                                                .find(c => c.id === category.id).influencers
                                                .find(i => i.userId === influencer.userId);
                                            let comments: undefined | CommentType[] = undefined;

                                            const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencer.userId);

                                            if (!entry) return;
                                            if (!slug) return;

                                            comments = entry.comments;

                                            return (
                                                <div
                                                    key={'influencer-' + index}
                                                    className="hand card w-60 bg-base-100 shadow-xl"
                                                >
                                                    <img
                                                        onClick={() => navigate(newRouteProfile)}
                                                        className='imgCardInfluencer'
                                                        src={influencer[entry.network]?.profile.picture}
                                                        alt={"picture-" + influencer[entry.network]?.profile.fullname}/>
                                                    <div className="card-body">
                                                        <h2
                                                            className="card-title whitespace-nowrap overflow-hidden text-ellipsis"
                                                            onClick={() => navigate(newRouteProfile)}
                                                        >
                                                            {influencerState && (
                                                                <FontAwesomeIcon size="1x"
                                                                                 icon={['fab', influencerState.network]}
                                                                                 color={'#133520'}/>
                                                            )}
                                                            {influencer[entry.network]?.profile.fullname}
                                                        </h2>
                                                        <div className="flex gap-4 items-center">
                                                            {comments && (
                                                                <div
                                                                    className={`nowrap badge ${comments.length > 0 ? "badge-error" : ""} badge-outline cursor-pointer`}
                                                                    onClick={() => {
                                                                        handleSelectInfluencerComment(influencer.userId)
                                                                    }}>💬 {comments.filter(obj => !obj.internal).length}</div>
                                                            )}
                                                            {influencer.signedCodeOfEthics && (
                                                                <img src={chartLogo} alt="Chart logo"
                                                                     style={{
                                                                         width: '40px',
                                                                         height: "40px"
                                                                     }}/>
                                                            )}
                                                        </div>
                                                        <p onClick={() => navigate(newRouteProfile)}>@{influencer[entry.network]?.profile.username || influencer[entry.network]?.profile.handle}</p>

                                                        <StateJudging
                                                            influencerId={influencer.userId}/>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* DATATABLE OF INFLUENCERS */}
            {typeOfList === "list" && (influencers && influencers.length > 0) &&
                influencerList?.influencersCategories.map(cat => {
                    return <JudgingDatatableFixed by={'category'}
                                                  handleSelectInfluencerComment={handleSelectInfluencerComment}
                                                  categoryId={cat.id}/>
                })}


            {typeOfList === "state" && (influencers && influencers.length > 0) &&
                <>
                    {['pending', 'loved', 'accepted', 'rejected'].map((state: any) => {
                        return <JudgingDatatableFixed by={'state'} state={state}
                                                      handleSelectInfluencerComment={handleSelectInfluencerComment}/>
                    })}
                </>
            }

            {typeOfList === "network" && (influencers && influencers.length > 0) &&
                <>
                    {['instagram', 'tiktok', 'youtube'].map((network) => {
                        return <JudgingDatatableFixed by={'network'} network={network as Networks}
                                                      handleSelectInfluencerComment={handleSelectInfluencerComment}/>
                    })}
                </>
            }

            {influencerList && selectedInfluencerId &&
                <InfluencerCommentsModal
                    name={commentModalName}
                    influencerList={influencerList}
                    influencerId={selectedInfluencerId}
                    showInCustomerViewMode={true}
                    onAddComment={(comment) => {
                        slug && refreshInfos(slug)
                    }}
                />
            }

        </div>
    )
}

type JudgingProps = {
    by: 'category' | 'state' | 'network',
    categoryId?: string,
    handleSelectInfluencerComment: (userId: string) => any,
    state?: InfluencerListInfluencerState['state']
    network?: Networks
}

function JudgingDatatableFixed({by, categoryId, state, network, handleSelectInfluencerComment}: JudgingProps) {
    // Hooks
    const navigate = useNavigate();
    const params = useParams();
    const {
        influencers,
        influencerList,
    } = useCustomer();

    const {t} = useTranslation();

    // States
    const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
    const [datatableTitle, setDatatableTitle] = useState<string>("");
    const [datatableSorting, setDatatabbleSorting] = useState<any>();

    const {slug} = useParams();

    useEffect(() => {

        if (by === 'category') {
            if (!influencerList || influencers.length === 0 || !categoryId) {
                setFilteredInfluencers([]);
                setDatatableTitle('')
                return;
            }

            const category = influencerList.influencersCategories.find(c => c.id === categoryId);
            if (!category) {
                toast.error(t("error-categorynot-found"));
                setDatatableTitle('')
                return;
            }

            setDatatableTitle(category.name);

            const ids = category.influencers.map(i => i.userId).flat();
            const filteredInfluencers = influencers.filter(i => ids.includes(i.userId));

            setFilteredInfluencers(filteredInfluencers);
        } else if (by === "state") {
            if (!influencerList || influencers.length === 0) {
                setFilteredInfluencers([]);
                setDatatableTitle('')
                return;
            }

            const allEntries = influencerList.influencersCategories.map(c => c.influencers).flat();
            const validEntries = allEntries.filter(e => e.state === state).map(e => e.userId);
            const filtered = influencers.filter(i => validEntries.includes(i.userId));
            setFilteredInfluencers(filtered);
            setDatatableTitle(t('labels.influencer_state.' + state));
        } else if ( by === "network") {
            if (!influencerList || influencers.length === 0 ) {
                setFilteredInfluencers([]);
                setDatatableTitle('')
                return;
            }

            const allEntries = influencerList.influencersCategories.map(c => c.influencers).flat();
            const validEntries = allEntries.filter(e => e.network === network).map(e => e.userId);
            const filtered = influencers.filter(i => validEntries.includes(i.userId));
            setFilteredInfluencers(filtered);
            setDatatableTitle(t('labels.networks.' + network));

        } else {
            if (!influencerList || influencers.length === 0 || (!state || !network)) {
                setFilteredInfluencers([]);
                setDatatableTitle('')
                return;
            }
        }
    }, [influencerList, influencers, categoryId, state]);

    const generateDatableColumns = () => {
        if (!influencerList) return;

        const columns: any[] = [
            {
                property: 'network',
                header: "",
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div className='flex gap-2 items-center' style={{width: '70px'}}>
                            <FontAwesomeIcon size="2x" icon={['fab', influencerState.network]}
                                             color={'#133520'}/>
                            {data.signedCodeOfEthics && (
                                <img src={chartLogo} alt="Chart logo" style={{width: '40px', height: "40px"}}/>
                            )}
                        </div>
                    )
                }
            },
            {
                property: 'name',
                header: t("labels.name"),
                primary: false,
                render: (data: Influencer) => {
                    let newRouteProfile = ROUTES.CUSTOMER.INFLUENCER.replace(":slug", params.slug as string).replace(":influencerId", data.userId);
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div className={'flex gap-2 items-center justify-between'}>
                            <div className="flex flex-row items-center hand" onClick={() => navigate(newRouteProfile)}>

                                <div className="avatar mr-2">
                                    <div className="w-12 rounded-full">
                                        <img className='h-44 rounded-xl'
                                             src={data[influencerState.network]?.profile.picture}
                                             alt="Influencer Image"/>
                                    </div>


                                </div>
                                <div className="flex flex-col text-start">
                                    <p className='font-bold'>
                                        {data[influencerState.network]?.profile.fullname}
                                    </p>
                                    <p>{("@" + (data[influencerState.network]?.profile.username || data[influencerState.network]?.profile.handle)) || ""}</p>
                                </div>
                                {data[influencerState.network]?.isVerified && (
                                    <img className='ml-2' src={imgVerified} alt="profile-verified-badge" width={16}
                                         height={16}/>
                                )}


                            </div>
                        </div>
                    )
                }
            },
        ];

        columns.push({
            property: 'status',
            header: t("table-status"),
            primary: false,
            render: (data: Influencer) => {
                const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                if (!influencerState) return;

                if (influencerState.status) {

                    if (influencerState.status === "shortlisted" || influencerState.status === "relationship_ended" || influencerState.status === "paid") {
                        return <p>{t('influencer.status.internal')}</p>
                    }

                    return <p>{t('influencer.status.' + influencerState.status)}</p>
                } else {
                    return <p>{t('influencer.status.not_started')}</p>
                }
            }
        })

        columns.push({
            property: 'comments',
            header: t("comments.comments-title"),
            primary: false,
            sortable: false,
            render: (data: Influencer) => {
                const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                if (!influencerState) return;
                const comments = influencerState.comments;

                if (comments) {
                    return (
                        <button
                            type='button'
                            className={`nowrap badge ${influencerState.comments.length > 0 ? "badge-error" : ""} badge-outline cursor-pointer`}
                            onClick={() => {
                                handleSelectInfluencerComment(data.userId)
                            }}>💬 {(comments as CommentType[]).filter(obj => !obj.internal).length}
                        </button>
                    )
                }
                return ""
            }
        })

        if (influencerList.settings.onList.showFollowers) {
            columns.push({
                property: 'followers',
                header: t("labels.followers"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{formatNumber(data[influencerState.network]?.profile.followers ?? 0)}</p>
                }
            })
        }

        if (influencerList.settings.onList.showEngagementRate) {
            columns.push({
                property: 'engagement',
                header: t("table-engagement"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{formatPercentage(data[influencerState.network]?.profile.engagementRate ?? 0)}%</p>
                }
            })
        }

        if (influencerList.settings.onList.showBio) {
            columns.push({
                property: 'showBio',
                header: t("labels.bio"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{data[influencerState.network]?.bio}</p>
                }
            })
        }

        if (influencerList.settings.onList.showTopAge) {
            columns.push({
                property: 'showTopAge',
                header: t("labels.top-age"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    const sorted = data[influencerState.network]?.audience?.ages?.sort((a, b) => b.weight - a.weight) || []
                    if (sorted.length > 0) {
                        return <p>{sorted[0].code}</p>
                    }
                    return ""
                }
            })
        }

        if (influencerList.settings.onList.showAudienceGender) {
            columns.push({
                property: 'showAudienceGender',
                header: t("labels.audience_gender"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    const genders = data[influencerState.network]?.audience?.genders || []
                    const maleValue = Math.floor((genders.find(s => s.code === "MALE")?.weight ?? 0) * 100);
                    const femaValue = 100 - maleValue;

                    if (maleValue > femaValue) {
                        return <p>Homme <b>{maleValue}%</b></p>
                    } else {
                        return <p>Femme <b>{femaValue}%</b></p>
                    }
                }
            })
        }

        if (influencerList.settings.onList.topOneCity) {
            columns.push({
                property: 'topOneCity',
                header: t("labels.top-one-city"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    const sorted = data[influencerState.network]?.audience?.geoCities?.sort((a, b) => b.weight - a.weight) || []
                    if (sorted.length > 0) {
                        return <p>{sorted[0].name}</p>
                    }
                    return ""
                }
            })
        }

        if (influencerList.settings.onList.topOneCountry) {
            columns.push({
                property: 'topOneCountry',
                header: t("labels.top-one-country"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    const sorted = data[influencerState.network]?.audience?.geoCountries?.sort((a, b) => b.weight - a.weight) || []
                    if (sorted.length > 0) {
                        return <p>{sorted[0].name}</p>
                    }
                    return ""
                }
            })
        }

        if (influencerList.settings.onList.nbComments) {
            columns.push({
                property: 'nbComments',
                header: t("labels.count-comments"),
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{formatNumber(data[influencerState.network]?.avgComments || 0)}</p>
                }
            })
        }

        if (influencerList.settings.onList.nbLikes) {
            columns.push({
                property: 'nbLikes',
                header: t("labels.count-likes"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{formatNumber(data[influencerState.network]?.avgLikes || 0)}</p>
                }
            })
        }

        if (influencerList.settings.onList.nbPublications) {
            columns.push({
                property: 'nbPublications',
                header: t("labels.count-posts"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    return <p>{formatNumber(data[influencerState.network]?.postsCount || 0)}</p>
                }
            })
        }

        if (influencerList.settings.onList.topFollowersEthnicOrigins) {
            columns.push({
                property: 'topFollowersEthnicOrigins',
                header: t("table-ethnicity"),
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;


                    if (influencerState.network === "youtube" || influencerState.network === 'tiktok') return <p>N/A</p>


                    const sorted = data[influencerState.network]?.audience?.ethnicities?.sort((a, b) => b.weight - a.weight) || []
                    if (sorted.length > 0) {
                        return <p>{sorted[0].name}</p>
                    }
                    return ""
                }
            })
        }

        if (influencerList.settings.onList.readings) {
            columns.push({
                property: 'readings',
                header: t("labels.count-views"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(u => u.userId === data.userId);
                    if (!influencerState) return;

                    const networkState = data[influencerState.network];

                    if (influencerState.network !== 'instagram') {
                        let nb: number = (influencerState.network === 'tiktok' ? networkState?.totalLikes : networkState?.totalViews) || 0;
                        return <p>{formatNumber((nb || 0) / (networkState?.postsCount || 0))}</p>
                    }

                    return <p>{formatNumber(networkState?.avgViews || networkState?.avgReelsPlays || 0)}</p>
                }
            })
        }

        columns.push({
            property: '',
            header: '',
            primary: false,
            sortable: false,
            render: (data: Influencer) => {
                const entry = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                if (!slug || !entry) return;

                return (
                    <div className="card-actions justify-center">
                        <StateJudging influencerId={data.userId}/>
                    </div>
                )
            }
        })
        return columns;
    }

    return (
        <>
            {datatableTitle && filteredInfluencers.length > 0 && (
                <div className='card p-4 my-4 card-border-dotted'>
                    <h2 className='text-2xl font-bold mb-4'>{datatableTitle}</h2>
                    <DataTable
                        sortable={true}
                        paginate={true}
                        background={["white", "light-2"]}
                        step={15}
                        onSort={setDatatabbleSorting}
                        sort={datatableSorting}
                        primaryKey="userId"
                        columns={generateDatableColumns()}
                        data={filteredInfluencers.sort((a, b) => {
                            const influencerStateA = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === a.userId);
                            const influencerStateB = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === b.userId);

                            if (!influencerStateA || !influencerStateB) return 0;

                            if (!datatableSorting) return (influencerStateA?.fame as string).localeCompare((influencerStateB?.fame as string));


                            switch (datatableSorting.property) {
                                case "followers":
                                    if (datatableSorting.direction === "asc") {
                                        // @ts-ignore
                                        return a[(influencerStateA as any).network]?.profile.followers - b[(influencerStateB as any).network]?.profile.followers;
                                    }
                                    // @ts-ignore
                                    return b[(influencerStateB as any).network]?.profile.followers - a[(influencerStateA as any).network]?.profile.followers;
                                case "engagement":
                                    if (datatableSorting.direction === "asc") {
                                        // @ts-ignore
                                        return a[(influencerStateA as any).network]?.profile.engagementRate - b[(influencerStateB as any).network]?.profile.engagementRate;
                                    }
                                    // @ts-ignore
                                    return b[(influencerStateB as any).network]?.profile.engagementRate - a[(influencerStateA as any).network]?.profile.engagementRate;

                                case "fame":
                                    if (datatableSorting.direction === "asc") {
                                        return (influencerStateA?.fame as string).localeCompare((influencerStateB?.fame as string));
                                    }
                                    return (influencerStateB?.fame as string).localeCompare((influencerStateA?.fame as string));

                                case "name":
                                    if (datatableSorting.direction === "asc") {
                                        // @ts-ignore
                                        return a[(influencerStateA as any).network]?.profile.fullname.localeCompare(b[(influencerStateB as any).network]?.profile.fullname);
                                    }
                                    // @ts-ignore
                                    return b[(influencerStateB as any).network]?.profile.fullname.localeCompare(a[(influencerStateA as any).network]?.profile.fullname);

                                case "status":
                                    if (datatableSorting.direction === "asc") {
                                        return influencerStateA.status.localeCompare(influencerStateB.status);
                                    }
                                    return influencerStateB.status.localeCompare(influencerStateA.status);

                                case "showBio":
                                    if (datatableSorting.direction === "asc") {
                                        // @ts-ignore
                                        return a[(influencerStateA as any).network]?.bio.localeCompare(b[(influencerStateB as any).network]?.bio);
                                    }
                                    // @ts-ignore
                                    return b[(influencerStateB as any).network]?.bio.localeCompare(a[(influencerStateA as any).network]?.bio);

                                case "showTopAge":
                                    const sortedA = a[influencerStateA.network]?.audience?.ages.sort((a2, b2) => b2.weight - a2.weight) || []
                                    const sortedB = b[influencerStateB.network]?.audience?.ages.sort((a2, b2) => b2.weight - a2.weight) || []

                                    if (sortedA.length === 0 || sortedB.length === 0) return 0;

                                    if (datatableSorting.direction === "asc") {
                                        return sortedA[0].code.localeCompare(sortedB[0].code);
                                    }
                                    return sortedB[0].code.localeCompare(sortedA[0].code);

                                case "showAudienceGender":
                                    const gendersA = a[influencerStateA.network]?.audience?.genders || []
                                    const gendersB = b[influencerStateB.network]?.audience?.genders || []

                                    if (gendersA.length === 0 || gendersB.length === 0) return 0;

                                    const maleA = Math.floor((gendersA.find(s => s.code === "MALE")?.weight ?? 0) * 100);
                                    const maleB = Math.floor((gendersB.find(s => s.code === "MALE")?.weight ?? 0) * 100);

                                    const bestA = maleA > (100 - maleA) ? maleA : 100 - maleA;
                                    const bestB = maleB > (100 - maleB) ? maleB : 100 - maleB;

                                    if (datatableSorting.direction === "asc") {
                                        return bestA - bestB;
                                    }
                                    return bestB - bestA;

                                case "topOneCity":
                                    const cityA = a[influencerStateA.network]?.audience?.geoCities.sort((a2, b2) => b2.weight - a2.weight) || []
                                    const cityB = b[influencerStateB.network]?.audience?.geoCities.sort((a2, b2) => b2.weight - a2.weight) || []

                                    if (cityA.length === 0 || cityB.length === 0) return 0;

                                    if (datatableSorting.direction === "asc") {
                                        return cityA[0].name.localeCompare(cityB[0].name);
                                    }

                                    return cityB[0].name.localeCompare(cityA[0].name);

                                case "topOneCountry":
                                    const countryA = a[influencerStateA.network]?.audience?.geoCountries.sort((a2, b2) => b2.weight - a2.weight) || []
                                    const countryB = b[influencerStateB.network]?.audience?.geoCountries.sort((a2, b2) => b2.weight - a2.weight) || []

                                    if (countryA.length === 0 || countryB.length === 0) return 0;

                                    if (datatableSorting.direction === "asc") {
                                        return countryA[0].name.localeCompare(countryB[0].name);
                                    }

                                    return countryB[0].name.localeCompare(countryA[0].name);

                                case "nbLikes":
                                    const nbLikesA = a[influencerStateA.network]?.avgLikes || 0;
                                    const nbLikesB = b[influencerStateB.network]?.avgLikes || 0;

                                    if (datatableSorting.direction === "asc") {
                                        return nbLikesA - nbLikesB;
                                    }

                                    return nbLikesB - nbLikesA;

                                case "nbPublications":
                                    const nbPublicationsA = a[influencerStateA.network]?.postsCount || 0;
                                    const nbPublicationsB = b[influencerStateB.network]?.postsCount || 0;

                                    if (datatableSorting.direction === "asc") {
                                        return nbPublicationsA - nbPublicationsB;
                                    }

                                    return nbPublicationsB - nbPublicationsA;

                                case "readings":
                                    let readingsA;
                                    let readingsB;

                                    if (influencerStateA.network !== "instagram") {
                                        let nbA: number = (influencerStateA.network === 'tiktok' ? a[influencerStateA.network]?.totalLikes : a[influencerStateA.network]?.totalViews) || 0;
                                        readingsA = (nbA || 0) / (a[influencerStateA.network]?.avgReelsPlays || 0)
                                    }
                                    readingsA = a[influencerStateA.network]?.avgViews || a[influencerStateA.network]?.avgReelsPlays || 0;

                                    if (influencerStateB.network !== "instagram") {
                                        let nbB: number = (influencerStateB.network === 'tiktok' ? b[influencerStateB.network]?.totalLikes : b[influencerStateB.network]?.totalViews) || 0;
                                        readingsB = (nbB || 0) / (b[influencerStateB.network]?.avgReelsPlays || 0)
                                    }
                                    readingsB = b[influencerStateB.network]?.avgViews || b[influencerStateB.network]?.avgReelsPlays || 0;

                                    if (datatableSorting.direction === "asc") {
                                        return readingsA - readingsB;
                                    }

                                    return readingsB - readingsA;

                            }

                            return 0
                        })}
                    />
                    <hr className='my-4'/>
                </div>
            )}
        </>
    )
}
