import React, {useCallback, useEffect, useState} from 'react';
import {useFirebase} from '../../contexts/FirebaseContext';

// Styles
import {DataTable} from 'grommet';
import {useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {InfluencerList, Networks} from "../../types/dbModels";

// Images - Flags
import networkTiktok from "../../assets/images/networks/tiktok.png";
import networkYoutube from "../../assets/images/networks/youtube.png";
import networkInstagram from "../../assets/images/networks/instagram.png";
import emptyFile from "../../assets/images/emptyfile.png";
import {User} from "../../types/user";
import Loading from "../loading/Loading";
import FormInput from "../FormInput";
import {useAuth} from "../../contexts/AuthContext";
import moment from 'moment';
import {ROUTES} from '../../constants/routes';
import {splitArrayIntoChunks} from "../../helpers/functions";

export default function InfluencerListsDatatable({influencerLists = [], refreshInfluencerLists, showFilters = true}: {
    influencerLists: InfluencerList[],
    refreshInfluencerLists: () => any,
    showFilters?: boolean
}) {
    const navigate = useNavigate();
    const {getUsersByUID} = useFirebase();
    const {currentUserData} = useAuth();
    const {t} = useTranslation();

    const [users, setUsers] = useState<User[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<{
        platform: Networks | "none",
        label: string,
        own: boolean,
        template: boolean,
        archived: boolean
    }>({platform: 'none', label: 'none', own: true, template: false, archived: false})


    useEffect(() => {
        reloadDataTable();
    }, []);

    useEffect(() => {
        loadUsers()
        loadLabels();
    }, [influencerLists]);

    function reloadDataTable() {
        refreshInfluencerLists();
    }

    async function loadUsers() {
        try {
            const allUserUids = [...new Set(influencerLists.map(list => list.creation_author))];

            const chunked = splitArrayIntoChunks(allUserUids, 29);
            let usrs: User[] = [];
            for (const chunk of chunked) {
                const temp = await getUsersByUID(chunk) || [];
                usrs.push(...temp);
            }

            setUsers(usrs);
        } catch (err) {
            toast.error(t("error-get-users"));
        }
    }

    function loadLabels() {
        const lbs = influencerLists
            .flatMap(l => l.labels)
            .filter((value, index, self) => value !== '-' && self.indexOf(value) === index)
            .sort((a, b) => (a === '-' ? -1 : b === '-' ? 1 : a.localeCompare(b)));

        setLabels(lbs);
    }

    function filterInputChange(event: any) {
        const filter = event.name.split('filter.')[1];
        setFilters(old => {
            return {
                ...old,
                [filter]: event.type !== "checkbox" ? event.value : event.checked
            }
        })
    }

    const getFilteredLists = useCallback(() => {
        return influencerLists.filter((list) => {
            const platformFilter = filters.platform === 'none' || list.network === filters.platform;
            const ownFilter = !filters.own || list.creation_author === currentUserData.uid;
            const templateFilter = !filters.template || (filters.template && list.isTemplate)
            const labelFilter = filters.label === 'none' || list.labels.includes(filters.label);

            const archivedFilter = filters.archived ? list.isArchived : !list.isArchived;
            // const archivedFilter = !filters.archived || (filters.archived && list.isArchived);

            return platformFilter && ownFilter && labelFilter && templateFilter && archivedFilter;
        });
    }, [filters, influencerLists, currentUserData.uid]);


    function renderNetwork(lang: any) {
        switch (lang) {
            case 'instagram':
                return <img src={networkInstagram} className="flag-table" alt='Instagram'/>;
            case 'youtube':
                return <img src={networkYoutube} className="flag-table" alt='Youtube'/>;
            case 'tiktok':
                return <img src={networkTiktok} className="flag-table" alt='Tiktok'/>;
        }
    }

    return (
        <>
            {loading && <Loading/>}

            {!loading && influencerLists.length === 0 && (
                <div className='flex flex-col justify-center text-center my-16'>
                    <img src={emptyFile} className='imgEmpty' alt="empty"/>
                    <p className='mt-8'>{t("no-list")}</p>
                </div>
            )}

            {!loading && influencerLists.length > 0 && users.length > 0 && (
                <>
                    {showFilters && (
                        <div>
                            <h3 className='font-bold text-xl'>{t("filters")}</h3>
                            <div className='flex  gap-2 justify-between w-full'>
                                {/*<PlatformFilter filterInputChange={filterInputChange} value={filters.platform}*/}
                                {/*                name={"filter.platform"}/>*/}
                                <div>
                                    <FormInput
                                        defaultValue={'none'}
                                        type="select"
                                        onChange={filterInputChange}
                                        name="filter.label"
                                        value={filters.label}
                                        label={t("labels-txt")}>
                                        <option value={'none'}>{t("all-masculine")}</option>
                                        {labels.map(l => (<option key={Math.random()} value={l}>{t(l)}</option>))}
                                    </FormInput>
                                </div>
                                <div className="flex justify-end gap-2">
                                    <FormInput
                                        className={'w-max'}
                                        type="checkbox"
                                        onChange={filterInputChange}
                                        name="filter.own"
                                        checked={filters.own}
                                        label={t("my-lists")}/>
                                    <FormInput
                                        className={'w-max'}
                                        type="checkbox"
                                        onChange={filterInputChange}
                                        name="filter.template"
                                        checked={filters.template}
                                        label={t("templates")}/>
                                    <FormInput
                                        className={'w-max'}
                                        type="checkbox"
                                        onChange={filterInputChange}
                                        name="filter.archived"
                                        checked={filters.archived}
                                        label={t("archived")}/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="my-10">
                        <DataTable
                            sortable={true}
                            paginate={true}
                            step={20}
                            background={["white", "light-2"]}
                            primaryKey="id"
                            onClickRow={(event) => {
                                navigate(ROUTES.EMPLOYEE.INFLUENCER_LIST.replace(':listId', event.datum.id))
                            }}
                            columns={[
                                {
                                    property: 'name',
                                    header: t('labels.name'),
                                    primary: false,
                                    search: true,
                                    render: data => {
                                        return (
                                            <div className="flex flex-row items-center">
                                                <div className="avatar mr-3 min-w-max">
                                                    {renderNetwork(data.network)}
                                                </div>
                                                <div className="flex flex-row text-start items-center">
                                                    <p className='font-bold'>{data.name}</p>
                                                    {/*{data.isArchived && <div className="badge badge-primary mr-1 txtEllipsis nowrap m-1 hand">{t('archived')}</div>}*/}
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    property: 'labels',
                                    header: t('labels.labels'),
                                    render: (data) => {
                                        if (data.labels.length > 0) {
                                            return (
                                                <div className="flex flex-row flex-wrap">
                                                    {data.labels.map((label: any, i: number) => (
                                                        <div
                                                            key={i}
                                                            className="badge badge-primary mr-1 txtEllipsis nowrap m-1 hand">
                                                            {t(label)}
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    },
                                    search: true
                                },
                                {
                                    property: 'market',
                                    header: t('list.market.label-plurial'),
                                    render: (data) => {
                                        return (
                                            <div className="flex flex-row flex-wrap">
                                                {data?.market?.map((market: any, i: number) => (
                                                    <div
                                                        key={i}
                                                        className="badge bg-blue-500 text-blue-100 mr-1 txtEllipsis nowrap m-1 hand">
                                                        {t("list.market." + market)}
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    },
                                    search: true
                                },
                                {
                                    property: 'status',
                                    header: t('labels.status'),
                                    search: true,
                                    render: (data) => {
                                        return (<>{t('labels.status_list.' + data.status)}</>)
                                    },
                                },
                                {
                                    property: 'isTemplate',
                                    header: t("type"),
                                    search: false,
                                    render: data => (
                                        <>{data.isTemplate ? t("template") : t("labels.list")}</>
                                    )
                                },
                                {
                                    property: 'creation_author',
                                    header: t("creator"),
                                    search: true,
                                    render: (data) => {
                                        const user = users.find(u => u.uid === data.creation_author);
                                        if (!user) {
                                            return <span style={{color: "red"}}>Utilisateur introuvable</span>
                                        }
                                        return (<>{user.firstname} {user.lastname}</>)
                                    },
                                },
                                {
                                    property: 'update_date',
                                    header: t("last-update"),
                                    search: false,
                                    render: (data) => {
                                        return (<>{moment(data.update_date).fromNow()}</>)
                                    },
                                },
                                {
                                    property: 'influencers',
                                    header: "👥",
                                    search: false,
                                    render: (data) => {
                                        return (
                                            <>{data.influencersCategories.map(c => c.influencers).flat().length}</>
                                        )
                                    }
                                }
                            ]}
                            data={getFilteredLists()}
                        />
                    </div>
                </>
            )}

        </>
    );
}
