import React, {useContext, useEffect, useState} from 'react';
import {db} from '../firebase';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';

import {createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail} from 'firebase/auth';


import {
    Campaign,
    Influencer,
    InfluencerList,
    InfluencerListInfluencerFame,
    InfluencerListInfluencerStatus, InfluencersCategory,
    Networks,
} from '../types/dbModels';
import {CampaignFormData, ListFormData, UserFormData} from '../types/formData';
import {User} from '../types/user';
import {InfluencerReport} from "../types/api";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useAuth} from "./AuthContext";
import {generateUid} from "../helpers/functions"
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

const FirebaseContext = React.createContext({});

type Context = {
    influencerLists: InfluencerList[]
    getSharedInfluencerListsForUser: (userId: string) => Promise<InfluencerList[]>,
    getCampaigns: () => Promise<Campaign[]>
    getCampaignsForUser: (id: any, mustActive ?: boolean) => Promise<Campaign[]>;
    getInfluencerLists: (platform ?: Networks) => Promise<InfluencerList[]>;
    getInfluencerList: (id: string) => Promise<InfluencerList | null>;
    getInfluencerListByCampaignId: (id: string) => Promise<InfluencerList | null>;
    getInfluencersFromIds: (ids: string[]) => Promise<Influencer[]>;
    getAllInfluencersWithCodeOfEthics: () => Promise<Influencer[]>;
    getUsers: () => Promise<User[]>;
    getAvailableInfluencerLists: () => Promise<InfluencerList[]>;
    getCampaignsWithNotList: () => Promise<Campaign[]>;
    getOngoingCampaigns: () => Promise<Campaign[]>
    getAvailableOffices: () => { id: string, text: string }[];
    getListStatusInternal: () => { id: string, text: string }[];
    getCampaignAvailableStatusExternal: () => { id: string, text: string }[];
    getInfluencerListAvailableMarkets: () => { id: string, value: string }[];
    getListsLabels: () => { id: string, text: string }[];
    getCampaign: (id: string) => Promise<Campaign | null>;
    getUser: (id: string) => Promise<User | null>;
    getUserByUID: (uid: string) => Promise<User | null>;
    getUsersByUID: (uid: string[]) => Promise<User[] | null>;
    getInfluencer: (id: string) => Promise<Influencer | null>
    putCampaign: (id: string, data: any) => Promise<any>;
    putInfluencerList: (id: string, data: any) => Promise<any>;
    putInfluencer: (userId: string, data: any) => Promise<any>
    putUser: (id: string, data: any) => Promise<any>
    addInfluencerToInfluencerList: (influencerListId: string, userId: string, network: Networks) => Promise<any>
    postNewCampaign: (campaignData: CampaignFormData) => Promise<any>;
    postInfluencerList: (list: ListFormData) => Promise<any>;
    postInfluencer: (influencerReport: InfluencerReport, platform: Networks) => Promise<any>;
    postUser: (userFormData: UserFormData) => Promise<any>;
    resetPassword: (email: string) => Promise<any>;
    postDuplicateInfluencerList: (list: InfluencerList) => Promise<any>;
    deleteInfluencerList: (influencerListId: string) => Promise<boolean>;
    deleteCampaign: (campaignId: string) => Promise<boolean>;
    getInfluencerAvailableFame: () => { id: InfluencerListInfluencerFame, text: string }[],
    getInfluencerAvailableStatus: () => { id: InfluencerListInfluencerStatus, text: string }[]
    bigInfluencerPatch: any
};

export function useFirebase(): Context {
    return useContext(FirebaseContext) as Context;
}

export function FirebaseProvider({children}: any) {
    const {t} = useTranslation();
    const {currentUser, currentUserData} = useAuth();
    const [influencerLists, setInfluencerLists] = useState<InfluencerList[]>([])


    const refreshLists = async () => {
        try {
            const lists = await getInfluencerLists()
            setInfluencerLists(lists)
        } catch (err) {

        }
    }

    useEffect(() => {
        refreshLists()
    }, []);

    // A utiliser juste pour le dev

    const patchInfluencers = async () => {
        try {
            const influencers = await getInfluencers();
            for (const influencer of influencers) {
                if (influencer?.signedCodeOfEthics !== false && influencer?.signedCodeOfEthics !== true) {
                    await putInfluencer(influencer.userId, {signedCodeOfEthics: false})
                }
            }
        } catch (err) {
        }
    }

    // const patchInfluencerLists = async () => {
    //     try {
    //         const lists = await getInfluencerLists();
    //         for (const list of lists) {
    //             if(!list?.market) {
    //                 list.market = []
    //             }
    //             await putInfluencerList(list.id, list)
    //         }
    //     } catch (err) {
    //     }
    // }

    // const patchInfluencerLists = async () => {
    //     try {
    //         const lists = await getInfluencerLists();
    //         for (const list of lists) {
    //             if(!list?.isArchived) {
    //                 list.isArchived = false;
    //             }
    //             await putInfluencerList(list.id, list)
    //         }
    //     } catch (err) {
    //     }
    // }

    const bigInfluencerPatch = async () => {
        // try {
        //     const lists = await getInfluencerLists();
        //     for (const list of lists) {
        //         if(!list?.market) {
        //             list.market = []
        //         }
        //         await putInfluencerList(list.id, list)
        //     }
        // } catch (err) {
        // }
    }

    // const bigInfluencerPatch = async () => {
    //     try {
    //         const lists = await getInfluencerLists();
    //         const filtered = lists.filter(list => {
    //             // if (list?.isArchived != false && list?.isArchived != true) return true;
    //             if ((typeof list?.isArchived) != "boolean") return true;
    //             // if((typeof list?.visible) != "boolean") return true;
    //
    //             if ((typeof list?.market) != "undefined" && list?.market != null) return false;
    //
    //             let ok = true;
    //             for (const category of list.influencersCategories) {
    //                 if (ok) {
    //                     for (const influencerState of category.influencers) {
    //                         if ((typeof influencerState.visible) != "boolean") {
    //                             ok = false
    //                         }
    //                     }
    //                 }
    //             }
    //             return !ok;
    //         })
    //         console.log("filtered")
    //         console.log(filtered)
    //
    //         for (const list of filtered) {
    //             const newList = {...list};
    //             if ((typeof list?.isArchived) != "boolean") {
    //                 newList.isArchived = false;
    //             }
    //
    //             if (!((typeof list?.market) != "undefined" && list?.market != null)) {
    //                 newList.market = [];
    //             }
    //
    //             for (const category of list.influencersCategories) {
    //                 for (const influencerState of category.influencers) {
    //                     if (!influencerState?.visible) {
    //                         influencerState.visible = true;
    //                     }
    //                 }
    //             }
    //
    //             await putInfluencerList(list.id, list)
    //         }
    //
    //
    //     } catch (err) {
    //         console.log("Une erreur est survenue pendant le patch des listes ")
    //     }
    // }

    const patchInfluencerLists = async () => {
        try {
            const lists = await getInfluencerLists();
            for (const list of lists) {

                for (const category of list.influencersCategories) {
                    for (const influencerState of category.influencers) {
                        if (!influencerState?.visible) {
                            influencerState.visible = true;
                        }
                    }
                }

                await putInfluencerList(list.id, list)
            }
        } catch (err) {
        }
    }

    const patchInfluencerStatus = async () => {
        try {
            const lists = await getInfluencerLists();
            for (const list of lists) {
                for (const category of list.influencersCategories) {
                    category.influencers = category.influencers.map(i => {
                        return {
                            ...i,
                            status: i?.status || "not_started"
                        }
                    })
                }
                await putInfluencerList(list.id, list)
            }
        } catch (err) {
        }
    }
    const deleteAllInfluencers = async () => {
        try {
            // #1 Collection
            const col = collection(db, 'Influencers');

            // #2 Query
            const q = query(col);

            // #3 Get documents
            const querySnapshot = await getDocs(q);

            // #4 Delete each document
            const deletePromises: any[] = [];
            querySnapshot.forEach((doc) => {
                const deletePromise = deleteDoc(doc.ref);
                deletePromises.push(deletePromise);
            });

            // #5 Wait for all deletions to complete
            await Promise.all(deletePromises);

            return true; // Indicate success
        } catch (error) {
            console.error("Error deleting influencers:", error);
            return false; // Indicate failure
        }
    };

    // -------------------------------------------
    // GET
    // -------------------------------------------

    // -------------------------------------------
    // ------USERS
    // -------------------------------------------

    const getUsers = () => {

        // #1 Collection
        const col = collection(db, 'Users');

        // #2 Query
        const q = query(col);
        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }) as Promise<User[]>;
    };

    const getUser = async (id: string): Promise<User | null> => {
        let userSnapchot;
        try {
            const userRef = doc(db, 'Users', id);
            userSnapchot = await getDoc(userRef);
        } catch (error) {
            toast.error(t("error-user-doesnt-exist"));
            return null;
        }

        if (!userSnapchot.data()) return null;

        return {
            id: userSnapchot.id,
            ...userSnapchot.data(),
        } as User;
    };

    const getUserByUID = async (uid: string): Promise<User | null> => {
        // #1 Collection
        const col = collection(db, 'Users');

        // #2 Query
        const q = query(col, where('uid', '==', uid));

        return getDocs(q).then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
                const doc = querySnapshot.docs[0];
                return {...doc.data(), id: doc.id}
            }
            return [];
        }) as Promise<User | null>;
    };

    const getUsersByUID = async (uids: string[]): Promise<User[] | null> => {
        console.log(uids)

        if (uids.length === 0) return [];

        // #1 Collection
        const col = collection(db, 'Users');

        // #2 Query
        const q = query(col, where('uid', 'in', uids));

        return getDocs(q).then((querySnapshot) => {
            const users: User[] = [];
            querySnapshot.forEach((doc) => {
                users.push({...doc.data(), id: doc.id} as User);
            });

            return users;
        }) as Promise<User[] | null>;
    };

    // -------------------------------------------
    // ------CAMPAIGNS
    // -------------------------------------------

    const getCampaigns = () => {
        // #1 Collection
        const col = collection(db, 'Campaigns');
        // #2 Query
        const q = query(col);

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc: any) => ({...doc.data(), id: doc.id}));
        }) as Promise<Campaign[]>;
    };

    const getCampaignsForUser = async (id: any, mustActive = false): Promise<Campaign[]> => {
        // #1 Collection
        const col = collection(db, 'Campaigns');

        // #2 Query
        const q = query(col, where('employee_incharge', '==', id));
        const aujourdHui = new Date().getTime();
        try {
            return await getDocs(q).then((querySnapshot) => {
                return querySnapshot.docs
                    .map((doc: any) => ({...doc.data(), id: doc.id}))
                    .filter(campaign => {
                        if (mustActive) {
                            return campaign.startDate <= aujourdHui && campaign.endDate > aujourdHui;
                        }
                        return true;
                    });
            });

        } catch {
            toast.error(t("error-campaign-getting"));
            return [];
        }
    };

    const getCampaignsWithNotList = (): Promise<Campaign[]> => {
        // #1 Collection
        const col = collection(db, 'Campaigns');

        // #2 Query
        const q = query(col, where('list', '==', ''));

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }) as Promise<Campaign[]>;
    };

    const getOngoingCampaigns = (): Promise<Campaign[]> => {
        // #1 Collection
        const col = collection(db, 'Campaigns');

        // #2 Query
        const nowTimestamp = new Date().getTime();
        const q = query(col,
            where('endDate', '>=', nowTimestamp)
        );

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }) as Promise<Campaign[]>;
    };

    const getListStatusInternal = (): { id: string; text: string }[] => {
        return [
            {id: 'draft', text: t('labels.status_list.draft')},
            {id: 'strategy', text: t('labels.status_list.strategy')},
            {id: 'signed', text: t('labels.status_list.signed')},
            {id: 'influencers', text: t('labels.status_list.influencers')},
            {id: 'in_progress', text: t('labels.status_list.in_progress')},
            {id: 'report', text: t('labels.status_list.report')}
        ];
    };

    const getCampaignAvailableStatusExternal = (): { id: string; text: string }[] => {
        return [
            {id: 'in_review', text: t("menu-influencers-choice")},
            {id: 'validated', text: t("menu-influencers-approved")},
        ];
    };

    const getInfluencerListAvailableMarkets = (): { id: string, value: string }[] => {
        return [
            {id: "market_roc", value: t('list.market.market_roc')},
            {id: "market_qc", value: t('list.market.market_qc')},
            {id: "market_usa", value: t('list.market.market_usa')},
            {id: "market_france", value: t('list.market.market_france')},
            {id: "market_europe", value: t('list.market.market_europe')},
            {id: "market_other", value: t('list.market.market_other')},
        ];
    }

    const getListsLabels = (): { id: string; text: string }[] => {
        return [
            {id: '-', text: "-"},
            {id: 'alcohols', text: t("alcohols")},
            {id: 'animals', text: t("animals")},
            {id: 'artist', text: t("artist")},
            {id: 'b2B', text: t("b2b")},
            {id: 'beauty', text: t("beauty")},
            {id: 'casinolotterie', text: t("casinolotterie")},
            {id: 'construction', text: t("construction")},
            {id: 'cooks', text: t("cooks")},
            {id: 'culinaryphotograph', text: t("culinaryphotograph")},
            {id: 'diy', text: t("diy")},
            {id: 'decoration', text: t("decoration")},
            {id: 'diversity', text: t("diversity")},
            {id: 'environmentallyfriendly', text: t("environmentallyfriendly")},
            {id: 'family', text: t("family")},
            {id: 'fashion', text: t("fashion")},
            {id: 'father', text: t("father")},
            {id: 'financebankinsurance', text: t("financebankinsurance")},
            {id: 'fooddrinks', text: t("fooddrinks")},
            {id: 'health', text: t("health")},
            {id: 'healthfitness', text: t("healthfitness")},
            {id: 'humor', text: t("humor")},
            {id: 'lgbtq', text: t("lgbtq")},
            {id: 'lifestyle', text: t("lifestyle")},
            {id: 'luxury', text: t("luxury")},
            {id: 'micro', text: t("micro")},
            {id: 'mid', text: t("mid")},
            {id: 'mother', text: t("mother")},
            {id: 'nano', text: t("nano")},
            {id: 'outdoor', text: t("outdoor")},
            {id: 'photograph', text: t("photograph")},
            {id: 'publicfigure', text: t("publicfigure")},
            {id: 'publicsector', text: t("publicsector")},
            {id: 'sexfluencer', text: t("sexfluencer")},
            {id: 'sport', text: t("sport")},
            {id: 'teacher', text: t("teacher")},
            {id: 'technology', text: t("technology")},
            {id: 'top', text: t("top")},
            {id: 'travel', text: t("travel")},

        ];
    };

    const getInfluencerAvailableFame = (): { id: InfluencerListInfluencerFame, text: string }[] => {
        return [
            {id: 'influencer', text: "fame.influencer"},
            {id: 'mid', text: "fame.mid"},
            {id: 'nano', text: "fame.nano"},
            {id: 'micro', text: "fame.micro"},
            {id: 'top', text: "fame.top"},
            {id: 'celebrity', text: "fame.celebrity"},
            {id: 'ugc_creator', text: "fame.ugc_creator"},

        ];
    }

    const getInfluencerAvailableStatus = (): { id: InfluencerListInfluencerStatus, text: string }[] => {
        return [
            {id: 'not_started', text: t('influencer.status.' + 'not_started')},
            {id: 'shortlisted', text: t('influencer.status.' + 'shortlisted')},
            {id: 'outreached', text: t('influencer.status.' + 'outreached')},
            {id: 'negotiating', text: t('influencer.status.' + 'negotiating')},
            {id: 'hired', text: t('influencer.status.' + 'hired')},
            {id: 'product_sent', text: t('influencer.status.' + 'product_sent')},
            {id: 'concept_received', text: t('influencer.status.' + 'concept_received')},
            {id: 'concept_validated', text: t('influencer.status.' + 'concept_validated')},
            {id: 'content_received', text: t('influencer.status.' + 'content_received')},
            {id: 'content_validated', text: t('influencer.status.' + 'content_validated')},
            {id: 'paid', text: t('influencer.status.' + 'paid')},
            {id: 'on_hold', text: t('influencer.status.' + 'on_hold')},
            {id: 'declined', text: t('influencer.status.' + 'declined')},
            {id: 'ghosted', text: t('influencer.status.' + 'ghosted')},
            {id: 'relationship_ended', text: t('influencer.status.' + 'relationship_ended')},
        ];
    }


    const getCampaign = async (id: string): Promise<Campaign | null> => {
        let campaignSnapchot;
        try {
            const campaignRef = doc(db, 'Campaigns', id);
            campaignSnapchot = await getDoc(campaignRef);
        } catch (error) {
            return null;
        }

        if (!campaignSnapchot.data()) return null;

        return {
            id: campaignSnapchot.id,
            ...campaignSnapchot.data(),
        } as Campaign;
    };

    // -------------------------------------------
    // ------INFLUENCER_LISTS
    // -------------------------------------------

    const getInfluencers = (): Promise<Influencer[]> => {

        const col = collection(db, 'Influencers');
        const q = query(col);

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        }) as Promise<Influencer[]>
    }

    const getInfluencerLists = (platform ?: Networks): Promise<InfluencerList[]> => {
        // #1 Collection
        const col = collection(db, 'Influencer_Lists');

        // #2 Query
        let q;

        if (!platform) {
            q = query(col);
        } else {
            q = query(col, where('network', '==', platform))
        }

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }) as Promise<InfluencerList[]>;
    };

    const getAvailableInfluencerLists = (): Promise<InfluencerList[]> => {
        // #1 Collection
        const col = collection(db, 'Influencer_Lists');

        // #2 Query
        const q = query(col, where('campaign', '==', ''));

        return getDocs(q).then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        }) as Promise<InfluencerList[]>;
    };

    const getInfluencerList = async (
        id: string
    ): Promise<InfluencerList | null> => {
        let listSnapshot;
        try {
            const listRef = doc(db, 'Influencer_Lists', id);
            listSnapshot = await getDoc(listRef);
        } catch (error) {
            toast.error(t("error-list-doesnt-exist"));
            return null;
        }

        if (!listSnapshot.data()) return null;

        return {
            id: listSnapshot.id,
            ...listSnapshot.data(),
        } as InfluencerList;
    };

    const getInfluencerListByCampaignId = async (
        id: string
    ): Promise<InfluencerList | null> => {
        let listSnapshot;
        try {
            const listRef = doc(db, 'Influencer_Lists', id);
            listSnapshot = await getDoc(listRef);
        } catch (error) {
            toast.error(t("error-list-doesnt-exist"));
            return null;
        }

        if (!listSnapshot.data()) return null;

        return {
            id: listSnapshot.id,
            ...listSnapshot.data(),
        } as InfluencerList;
    };


    const getSharedInfluencerListsForUser = async (userId: string): Promise<InfluencerList[]> => {
        try {
            const listIds = (await getCampaignsForUser(userId)).map(c => c.list).filter(l => l);
            const mappedLists = []

            for (const listId of listIds) {
                const list = await getInfluencerList(listId)
                if (list) {
                    mappedLists.push(list)
                }
            }

            return mappedLists;
        } catch (error) {
            return [];
        }
    }

    // -------------------------------------------
    // ------INFLUENCERS
    // -------------------------------------------

    const getInfluencer = async (userId: string) => {
        try {
            // #1 Collection
            const col = collection(db, 'Influencers');

            // #2 Query
            const q = query(col, where('userId', '==', userId));

            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
                return {
                    ...querySnapshot.docs[0].data(),
                    id: querySnapshot.docs[0].id
                } as Influencer
            }
            return null

        } catch (err) {
            console.error(t("error-influencer-doesnt-exist"));
            return null;
        }
    };

    const getInfluencersFromIds = async (userIds: string[]) => {
        const influencers: Influencer[] = [];
        for (const userId of userIds) {
            const influencer = await getInfluencer(userId);
            if (influencer) {
                influencers.push(influencer)
            }
        }
        return influencers
    };

    const getAllInfluencersWithCodeOfEthics = async (): Promise<Influencer[]> => {
        let influencersSnapshot;
        try {
            const influencersRef = collection(db, 'Influencers');

            let queryRef = query(influencersRef, where("signedCodeOfEthics", "==", true));

            influencersSnapshot = await getDocs(queryRef);

        } catch (error) {
            return []
            // throw new Error("Une erreur est survenue lors de la récupération des influenceurs signataires de la charte éthique des influenceurs")
        }

        return influencersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        })) as Influencer[]
    }

    // -------------------------------------------
    // ------OTHERS
    // -------------------------------------------

    const getAvailableOffices = (): { id: string; text: string }[] => {
        return [
            {id: 'montreal', text: 'Montréal'},
            {id: 'paris', text: 'Paris'},
            {id: 'toronto', text: 'Toronto '},
        ];
    };

    // -------------------------------------------
    // PUT
    // -------------------------------------------

    const putInfluencerList = async (id: string, data: any): Promise<any> => {
        let listRef;
        try {
            data.update_date = new Date().getTime();
            listRef = doc(db, 'Influencer_Lists', id);
            await updateDoc(listRef, data);
            refreshLists()
            return true;
        } catch (error) {
            toast.error(t("error-list-editing"))
            return false;
        }
    };

    const putUser = async (id: string, data: any): Promise<any> => {
        let userRef;
        try {
            if (!currentUserData) {
                throw new Error(t("error-not-connected"))
            }

            if (data['role'] && currentUserData?.role !== 'admin') {
                throw new Error(t("error-autorization-edit-role"));
            }

            userRef = doc(db, 'Users', id);
            toast.success(t("message-success-user-updated"));
        } catch (error: any) {
            toast.error(error?.message ?? t("error-list-editing"));
            return;
        }

        await updateDoc(userRef, data);
    };

    const addInfluencerToInfluencerList = async (influencerListId: string, userId: string, network: Networks): Promise<any> => {
        const influencerList = await getInfluencerList(influencerListId);

        if (!influencerList) return;

        const influencersCategories = [...influencerList.influencersCategories];

        const influencers = influencersCategories[0].influencers;

        if (influencers.findIndex(i => i.userId === userId) < 0) {
            influencers.push({
                userId,
                state: "pending",
                comments: [],
                fame: "influencer",
                network: network,
                status: "not_started",
                visible: true
            })
        }

        const listRef = doc(db, 'Influencer_Lists', influencerListId);
        await updateDoc(listRef, {"influencersCategories": influencersCategories});

        refreshLists()
    };

    const putInfluencer = async (userId: string, data: any): Promise<any> => {
        try {
            const influencer = await getInfluencer(userId);
            if (!influencer) {
                throw new Error(t("error-influencer-doesnt-exist"))
            }

            const influencerDocRef = doc(db, 'Influencers', influencer.id);
            await updateDoc(influencerDocRef, data);


        } catch (error: any) {
            console.error(error?.message ?? t("error-influencer-doesnt-exist"));
            return;
        }

    };

    const putCampaign = async (id: string, data: any): Promise<any> => {
        let campaignRef;
        try {
            campaignRef = doc(db, 'Campaigns', id);
        } catch (error) {
            return;
        }

        await updateDoc(campaignRef, data);
    };

    // -------------------------------------------
    // POST
    // -------------------------------------------

    const postNewCampaign = async (
        campaignData: CampaignFormData
    ): Promise<any> => {
        const colec = collection(db, 'Campaigns');

        const campaign: Omit<Campaign, 'id'> = {
            name: campaignData.name,
            startDate: new Date(campaignData.startDate).getTime(),
            endDate: new Date(campaignData.endDate).getTime(),
            status_ext: campaignData.status_ext,
            client: campaignData.client,
            employee_incharge: campaignData.employee_incharge,
            office: campaignData.office,
            list: campaignData.list,
            creation_date: new Date().getTime(),
            update_date: new Date().getTime(),
            nb_expected: campaignData.nb_expected
        };

        const addedDoc = await addDoc(colec, campaign);

        const id = (addedDoc as any)._key.path.segments[1];
        if (id && campaignData.list) {
            await putInfluencerList(campaignData.list, {campaign: id});
        }

        return addedDoc;
    };

    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(getAuth(), email);
            toast.success(t("users.message-success-reset-password"));
        } catch (error: any) {
            toast.error(error?.message ?? t("users.error-reset-password"));
        }
    }

    const postUser = async (
        userFormData: UserFormData
    ): Promise<any> => {

        try {
            if (!currentUserData) {
                throw new Error(t("error-not-connected"))
            }

            if (currentUserData?.role !== 'admin') {
                throw new Error(t("error-autorization-add-user"));
            }

            const authResponse = await createUserWithEmailAndPassword(
                getAuth(),
                userFormData?.email,
                userFormData?.password
            );

            if (!authResponse) return;

            const newFormData: { uid: string } & Omit<UserFormData, 'password' | 'confirmPassword'> = {
                uid: authResponse.user.uid,
                role: userFormData?.role,
                avatar: userFormData?.avatar,
                email: userFormData?.email,
                firstname: userFormData?.firstname,
                lastname: userFormData?.lastname,
            }


            const colec = collection(db, 'Users');
            const addedDoc = await addDoc(colec, newFormData);

            toast.success(t("message-success-create-user"));

            return addedDoc;
        } catch (error: any) {
            toast.error(error?.message ?? t("error-user-create"))
        }

    };

    const postInfluencer = async (
        influencerReport: InfluencerReport,
        platform: Networks
    ): Promise<any> => {
        const colec = collection(db, 'Influencers');

        const influencer: Omit<Influencer, "id"> = {
            userId: influencerReport.profile.userId,
            update_date: new Date().getTime(),
            signedCodeOfEthics: false,
        }
        influencer[platform] = influencerReport.profile;

        return await addDoc(colec, influencer);
    };

    const postInfluencerList = async (list: ListFormData): Promise<any> => {
        const colec = collection(db, 'Influencer_Lists');

        const l: Omit<InfluencerList, 'id'> = {
            name: list.name,
            network: list.network,
            campaign: list.campaign,
            labels: list.labels.filter((l) => l !== ''),
            market: list.market,
            password: list.password,
            status: 'draft',
            influencersCategories: [
                {id: generateUid(), name: "General", influencers: []}
            ],
            contributors: [],
            note: list.note ?? "",
            settings: {
                onDetails: {
                    showGender: true,
                    showAge: true,
                    showCity: true,
                    showCountry: true,
                    showFollowers: true,
                },
                onList: {
                    showFollowers: true,
                    showEngagementRate: true,
                    showBio: false,
                    showTopAge: false,
                    showAudienceGender: false,
                    topOneCity: false,
                    topOneCountry: false,
                    nbComments: false,
                    nbLikes: false,
                    nbPublications: false,
                    topFollowersEthnicOrigins: false,
                    readings: false,
                }
            },
            creation_author: currentUserData.uid,
            creation_date: new Date().getTime(),
            update_date: new Date().getTime(),
            link: generateUid(),
            banner: list.banner,
            isTemplate: list.isTemplate,
            isArchived: list.isArchived
        };

        const addedDoc = await addDoc(colec, l);

        const id = (addedDoc as any)._key.path.segments[1];
        if (id && list.campaign) {
            await putCampaign(list.campaign, {list: id});
        }

        refreshLists()

        return addedDoc;
    };

    const postDuplicateInfluencerList = async (list: InfluencerList): Promise<any> => {
        const colec = collection(db, 'Influencer_Lists');

        const nList: any = {};

        for (const [key, value] of Object.entries(list)) {
            if (key === 'name') {
                nList[key] = t("labels.copy-of") + value;
            } else if (key === 'id') {

            } else if (key === 'creation_author') {
                nList[key] = currentUserData.uid
            } else if (key === 'campaign') {
                nList[key] = '';
            } else if (key === 'link') {
                nList[key] = generateUniqueID();
            } else if (key === "influencersCategories") {
                let tmp = [...(value as InfluencersCategory[])];
                tmp = tmp.map(category => {
                    category.influencers = category.influencers.map(influencerState => {
                        influencerState.comments = [];
                        influencerState.state = "pending";
                        return influencerState;
                    });
                    return category;
                });

                nList[key] = tmp;
            } else {
                nList[key] = value
            }
        }

        const addedDoc = await addDoc(colec, nList);

        // const id = (addedDoc as any)._key.path.segments[1];
        // if (id && list.campaign) {
        //     await putCampaign(list.campaign, {list: id});
        // }

        refreshLists()

        return addedDoc;
    };

    // -------------------------------------------
    // DELETE
    // -------------------------------------------

    const deleteCampaign = async (campaignId: string) => {
        try {

            // Delete Campaign
            const campainRef = doc(db, "Campaigns", campaignId);
            await deleteDoc(campainRef);

            // Clean the corresponding list (if found)
            const col = collection(db, 'Influencer_Lists');
            const q = query(col, where('campaign', '==', campaignId));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (list) => {
                await putInfluencerList(list.id, {campaign: ''});
            });

            return true;
        } catch (error) {
            return false;
        }
    }

    const deleteInfluencerList = async (influencerListId: string) => {
        try {
            const influencerList = await getInfluencerList(influencerListId);

            if (influencerList) {

                // Verification de si elle a une campagne
                const campaigns = await getCampaigns();
                const campaginIndex = campaigns.findIndex(c => c.list === influencerListId);
                if (campaginIndex > -1) {
                    // Edition de la campagne
                    const campaign = campaigns[campaginIndex];
                    await deleteCampaign(campaign.id);
                }

                // Suppression de la liste
                const listRef = doc(db, "Influencer_Lists", influencerListId);
                await deleteDoc(listRef);
                refreshLists()
                return true;
            }
            return false;

        } catch (error) {
            return false;
        }
    }

    // -------------------------------------------
    // VALUE
    // -------------------------------------------

    const value: Context = {
        influencerLists,
        getSharedInfluencerListsForUser,
        // GET
        getCampaigns,
        getCampaignsForUser,
        getInfluencerLists,
        getInfluencersFromIds,
        getAllInfluencersWithCodeOfEthics,
        getInfluencerListByCampaignId,
        getUsers,
        getAvailableInfluencerLists,
        getCampaignsWithNotList,
        getOngoingCampaigns,
        getAvailableOffices,
        getListStatusInternal,
        getCampaignAvailableStatusExternal,
        getInfluencerListAvailableMarkets,
        getListsLabels,
        getInfluencerAvailableFame,
        getInfluencerAvailableStatus,
        getCampaign,
        getUser,
        getUserByUID,
        getUsersByUID,
        getInfluencer,
        getInfluencerList,
        // PUT
        putCampaign,
        putInfluencerList,
        putInfluencer,
        putUser,
        addInfluencerToInfluencerList,
        // POST
        postNewCampaign,
        postInfluencerList,
        postInfluencer,
        postUser,
        resetPassword,
        postDuplicateInfluencerList,
        // Delete
        deleteInfluencerList,
        deleteCampaign,
        // Others
        bigInfluencerPatch
    };

    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    );
}
